import React, {useEffect, useRef, useState} from 'react';
import s from './Main.module.sass'
import logo from '../../img/logo.svg'
import navImg from '../../img/navImg.svg'
import card1 from '../../img/card1.svg'
import card2 from '../../img/card2.svg'
import card3 from '../../img/card3.svg'
import card4 from '../../img/card4.svg'
import serv1 from '../../img/serv1.png'
import serv2 from '../../img/serv2.png'
import serv3 from '../../img/serv3.png'
import serv4 from '../../img/serv4.png'
import paper from '../../img/lists.png'
import cloud1 from '../../img/cloud1.png'
import cloud2 from '../../img/cloud2.png'
import scrollArrow from '../../img/scrollArrow.svg'
import goldArrowLeft from '../../img/goldArrowLeft.svg'
import contactUs from '../../img/contactUs.svg'
import cloudText1 from '../../img/cloudSaveText.svg'
import cloudText2 from '../../img/cloudSaveText2.svg'
import {ElemScroll} from "./ElemScroll";
import {CustomModal} from "../Modal/CustomModal";
import {CustomHeader} from "../CustomHeader";
import {FooterLeaveRequest} from "../FooterLeaveRequest";
import {Link} from "react-router-dom";
import AnchorComponent from "../AnchorComponent";

export const Main = () => {
    const servicesList = [
        {
            day: '3-5 дней',
            title: 'Cканирование документов',
            subTitle: 'Быстрое сканирование бухгалтерской первичной документации, юридических и финансовых документов, анкет и бланков ',
            path: '/scan-documents',

        },
        {
            day: '1-2 дня',
            title: 'Широкоформатное сканирование',
            subTitle: 'Оцифровка чертежей, проектов, карт и других документов размеров А2, А1, А0+ на наших широкоформатных сканерах',
            path: '/wide-format-scanning',

        },
        {
            day: '4-5 Дней',
            title: 'Опись, сверка и инвентаризация',
            subTitle: 'Производим опись и сверку документов, индексируя их по заданным параметрам и вводя атрибуты в ваш электронный архив или документооборот',
            path: '/reconciliation-stocktaking',

        },
        {
            day: '2 дня',
            title: 'Перевозка архива',
            subTitle: 'Перевозка архивной документации, независимо от размера и объема, ваш архив будет доставлен в нужное место своевременно и без повреждений',
            path: '/archive-transportation',

        },
        {
            day: '2 дня',
            title: 'Сканирование книг',
            subTitle: 'Безопасно и точно оцифруем бумажные книги с помощью профессиональных сканеров формата A2, сохраняя высокое качество текста и изображений',
            path: '/scanning-books',

        },
        {
            day: '1-2 дня',
            title: 'Бесконтактное сканирование',
            subTitle: 'Мы оцифровываем уникальные старинные книги, карты, газеты и журналы с особой тщательностью и точностью, чтобы сохранить их для будущих поколений',
            path: '/non-contact-scanning',

        },
        {
            day: '6-7 дней',
            title: 'Электронный архив',
            subTitle: 'Виртуальное хранилище информации предоставляет вам возможность удаленного доступа, быстрого поиска и выгрузки данных',
            path: '/electronic-archive',

        },
        {
            day: '5 дней',
            title: 'Индексация документов',
            subTitle: 'Индексируем документы по заданным критериям, вводим атрибуты в ваш электронный архив или документооборот, создаем удобные Excel-реестры',
            path: '/document-indexing',

        },
        {
            day: '3 дня',
            title: 'Уничтожение документов ',
            subTitle: 'Уничтожение конфиденциальных документов в соответствии с законодательством и требованиями безопасности',
            path: '/document-destruction',

        },
        {
            day: '1-2 дня',
            title: 'Депозитарное хранение',
            subTitle: 'Освободив ваши площади, мы предоставим высокий уровень защиты и контроля доступа к вашей информации',
            path: '/depositary-custody',

        },
        {
            day: '3 дня',
            title: 'Консалтинговые услуги',
            subTitle: 'Специализируемся на создании связей между вашим электронным архивом и юридически значимым документооборотом, через API или Коннектор Диадок',
            path: '/consulting-services',

        },
        {
            day: '7 дней',
            title: 'Подготовка и сдача дел в госархив',
            subTitle: 'Мы обеспечиваем полное соответствие требованиям и процедурам, гарантируя безопасность и точность каждого этапа процесса',
            path: '/state-archives',

        },
        {
            day: '3 дня',
            title: 'Копирование',
            subTitle: 'Мы осуществляем копирование документов всех стандартных размеров (А4, А3, А2, А1, А0) и нестандартных (А3х3, А3х4, А2х3, А2х4, А1х3 и так далее).',
            path: '//copy-documents',

        },
        {
            day: '1-2 дня',
            title: 'Постпечатная обработка',
            subTitle: 'Заключительный этап в производстве печатной продукции, который включает различные виды работ по финишированию материалов после их печати.',
            path: '/print-processing',

        },
        {
            day: '4 дня',
            title: 'Инженерная печать',
            subTitle: 'Осуществляем печать чертежей, конструкторских документов, предназначенных для использования в строительстве, архитектуре и проектировании.',
            path: '/engineering-print',

        },
    ]
    return (
        <>
            <AnchorComponent/>
            <div className={s.section1}>
                <CustomHeader/>
                <div className={s.container}>
                    <div className={s.littleInfo}>Оцифруем для будущего<br className={s.deleteOnMobileBr}/>
                    </div>
                    <div></div>
                    <h1>
                        Сканирование документов
                    </h1>
                    <div className={s.secondLittleInfo}>Закажите обратный звонок и получите -10% <br/>на оцифровку
                        вашего проекта
                        <a href="#footer" style={{textDecoration: 'none'}}><button  className={s.contactBtn}>Оставить заявку</button></a>
                    </div>
                </div>

            </div>

            <div className={s.advantages}>
                <div className={s.container}>
                    <h2 className={s.title}>
                        преимущества
                        <span>ADVANTAGES</span>
                    </h2>
                    <div className={s.cards}>
                        <div className={s.card}>
                            <img src={card1} alt="icon"/>
                            <div className={s.titleCard}>Удобство доступа и поиска</div>
                            <div className={s.subTitleCard}>Вы получаете возможность легкого и быстрого доступа к вашим
                                файлам и документам в любое время и из любого места. Наш электронный архив предоставляет
                                удобную поисковую систему, позволяющая находить необходимые документы за считанные
                                секунды
                            </div>
                        </div>
                        <div className={s.card}>
                            <img src={card2} alt="icon"/>
                            <div className={s.titleCard}>Повышение безопасности данных</div>
                            <div className={s.subTitleCard}>Электронный архив позволяет надежно защищать информацию с
                                помощью различных методов шифрования и создания резервных копий.
                            </div>
                        </div>
                        <div className={s.card}>
                            <img src={card3} alt="icon"/>
                            <div className={s.titleCard}>Экономия места и ресурсов</div>
                            <div className={s.subTitleCard}>Сканирование и перевод документации в электронный формат
                                значительно сокращает необходимость в офисном пространстве для хранения архивов и
                                снижает расходы на бумагу и печать.
                            </div>
                        </div>
                        <div className={s.card}>
                            <img src={card4} alt="icon"/>
                            <div className={s.titleCard}>Улучшение совместной работы
                            </div>
                            <div className={s.subTitleCard}>Электронный архив облегчит процессы совместной работы внутри
                                организации. К тому же, сканированные данные легко интегрировать с другими
                                бизнес-процессами и системами управления, что повышает общую эффективность работы
                                компании.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id={'services'} className={s.services}>
                <div className={s.container}>
                    <h2 className={s.titleRight}>
                        УСЛУГИ
                        <span>SERVICES</span>
                    </h2>
                    <div style={{position: 'relative'}}>
                        <img className={s.scrollArrow} src={scrollArrow} alt="img"/>
                        <img className={s.paper} src={paper} alt="paper"/>
                        <div className={s.scrollBox}>
                            {servicesList.map(el => {
                                return <ElemScroll day={el.day} title={el.title} subtitle={el.subTitle} path={el.path}/>
                            })}

                        </div>
                    </div>
                </div>
            </div>

            <div className={s.serviceComplex}>
                <div className={s.container}>
                    <h2 className={s.titleGold}>
                        КОМПЛЕКС УСЛУГ
                        <span>complex of services</span>
                    </h2>
                    <h3> Представляем вам комплексное решение для трансформации ваших документов – сканирование и
                        электронный архив с мгновенным доступом к вашей информации. Мы обеспечим вам безопасный переход
                        к бесшовному электронному документообороту, который позволит:</h3>
                    <div className={s.listComplexService}>
                        <div className={s.elemCompSevice}>
                            <p>Экономить время</p>
                            <img src={serv1} alt="service"/>
                        </div>
                        <div className={s.elemCompSevice}>
                            <p>Освободить пространство</p>
                            <img src={serv2} alt="service"/>
                        </div>
                        <div className={s.elemCompSevice}>
                            <p> Обеспечить безопасность</p>
                            <img src={serv3} alt="service"/>
                        </div>
                        <div className={s.elemCompSevice}>
                            <p>Улучшить доступность</p>
                            <img src={serv4} alt="service"/>
                        </div>
                    </div>
                    <Link style={{textDecoration: 'none'}} to={'/range-services'}>
                        <button className={s.standardBtn}>Подробнее ></button>
                    </Link>
                </div>
            </div>

            <div className={s.digitalArchive}>
                <div className={s.container}>
                    <h2 style={{color: '#000810'}} className={s.titleRight}>
                        Электронный архив
                        <span>digital archive</span>
                    </h2>

                    <div className={s.cloudSavers}>

                            <Link style={{textDecoration: 'none'}} to={'/electronic-archive#cloudSaver'} >
                                <div className={s.cloud}>
                                    <img className={s.cloudText} src={cloudText1} alt="cloud"/>
                                    <img src={cloud1} alt="img"/>
                                    <div>Облачное хранение документов обеспечивает доступ к данным с любого устройства, и
                                        повышает их защиту с помощью резервных копий на удаленных серверах.
                                    </div>
                                </div>
                            </Link>
                        <Link style={{textDecoration: 'none'}} to={'/electronic-archive#localSaver'} >
                            <div className={s.cloud}>
                                <img className={s.cloudText} src={cloudText2} alt="cloud"/>
                                <img src={cloud2} alt="img"/>
                                <div>Локальное хранилище обеспечивает безопасное хранение данных
                                    с ограниченным доступом, защищая документы от внешних угроз.
                                </div>
                            </div>
                        </Link>

                    </div>


                </div>
            </div>

            <FooterLeaveRequest/>

        </>
    );
};

// Эконом времен
// В select option background голубой